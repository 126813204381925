export const UPDATE_INIT_STATE = 'UPDATE_INIT_STATE'

export const AUTHENTICATE_USER_START = 'AUTHENTICATE_USER_START'
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS'
export const AUTHENTICATE_USER_FAIL = 'AUTHENTICATE_USER_FAIL'

export const GET_USER_START = 'GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'AUTHENTICATE_USER_FAIL'

export const GET_CLIENTS_START = 'GET_CLIENTS_START'
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_FAIL = 'AUTHENTICATE_CLIENTS_FAIL'

export const GET_GOALS_START = 'GET_GOALS_START'
export const GET_GOALS_SUCCESS = 'GET_GOALS_SUCCESS'
export const GET_GOALS_FAIL = 'GET_GOALS_FAIL'

export const GET_GOAL_PROJECTION_START = 'GET_GOAL_PROJECTION_START'
export const GET_GOAL_PROJECTION_SUCCESS = 'GET_GOAL_PROJECTION_SUCCESS'
export const GET_GOAL_PROJECTION_FAIL = 'GET_GOAL_PROJECTION_FAIL'

export const GET_GOAL_START = 'GET_GOAL_START'
export const GET_GOAL_SUCCESS = 'GET_GOAL_SUCCESS'
export const GET_GOAL_FAIL = 'GET_GOAL_FAIL'

export const GET_FUNDING_START = 'GET_FUNDING_START'
export const GET_FUNDING_SUCCESS = 'GET_FUNDING_SUCCESS'
export const GET_FUNDING_FAIL = 'GET_FUNDING_FAIL'

export const CREATE_FUNDING_START = 'CREATE_FUNDING_START'
export const CREATE_FUNDING_SUCCESS = 'CREATE_FUNDING_SUCCESS'
export const CREATE_FUNDING_FAIL = 'CREATE_FUNDING_FAIL'

export const CREATE_TRANSACTION_START = 'CREATE_TRANSACTION_START'
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS'
export const CREATE_TRANSACTION_FAIL = 'CREATE_TRANSACTION_FAIL'

export const GET_GOAL_PERFORMANCE_START = 'GET_GOAL_PERFORMANCE_START'
export const GET_GOAL_PERFORMANCE_SUCCESS = 'GET_GOAL_PERFORMANCE_SUCCESS'
export const GET_GOAL_PERFORMANCE_FAIL = 'GET_GOAL_PERFORMANCE_FAIL'

export const UPDATE_GOAL_START = 'UPDATE_GOAL_START'
export const UPDATE_GOAL_SUCCESS = 'UPDATE_GOAL_SUCCESS'
export const UPDATE_GOAL_FAIL = 'UPDATE_GOALS_FAIL'

export const GET_QUESTIONNAIRES_START = 'GET_QUESTIONNAIRES_START'
export const GET_QUESTIONNAIRES_SUCCESS = 'GET_QUESTIONNAIRES_SUCCESS'
export const GET_QUESTIONNAIRES_FAIL = 'GET_QUESTIONNAIRES_FAIL'

export const GET_NODES_START = 'GET_NODES_START'
export const GET_NODES_SUCCESS = 'GET_NODES_SUCCESS'
export const GET_NODES_FAIL = 'GET_NODES_FAIL'

export const GET_NODE_RELATIONSHIPS_START = 'GET_NODE_RELATIONSHIPS_START'
export const GET_NODE_RELATIONSHIPS_SUCCESS = 'GET_NODE_RELATIONSHIPS_SUCCESS'
export const GET_NODE_RELATIONSHIPS_FAIL = 'GET_NODE_RELATIONSHIPS_FAIL'

export const GET_ALLOCATIONS_START = 'GET_ALLOCATIONS_START'
export const GET_ALLOCATIONS_SUCCESS = 'GET_ALLOCATIONS_SUCCESS'
export const GET_ALLOCATIONS_FAIL = 'GET_ALLOCATIONS_FAIL'

export const GET_ACCOUNT_ALLOCATION_START = 'GET_ACCOUNT_ALLOCATION_START'
export const GET_ACCOUNT_ALLOCATION_SUCCESS = 'GET_ACCOUNT_ALLOCATION_SUCCESS'
export const GET_ACCOUNT_ALLOCATION_FAIL = 'GET_ACCOUNT_ALLOCATION_FAIL'

export const GET_ALLOCATION_PERFORMANCE_START = 'GET_ALLOCATION_PERFORMANCE_START'
export const GET_ALLOCATION_PERFORMANCE_SUCCESS = 'GET_ALLOCATION_PERFORMANCE_SUCCESS'
export const GET_ALLOCATION_PERFORMANCE_FAIL = 'GET_ALLOCATION_PERFORMANCE_FAIL'

export const GET_CLIENT_ANN_VOLUME_START = 'GET_CLIENT_ANN_VOLUME_START'
export const GET_CLIENT_ANN_VOLUME_SUCCESS = 'GET_CLIENT_ANN_VOLUME_SUCCESS'
export const GET_CLIENT_ANN_VOLUME_FAIL = 'GET_CLIENT_ANN_VOLUME_FAIL'

export const GET_CLIENT_CUM_RETURN_START = 'GET_CLIENT_CUM_RETURN_START'
export const GET_CLIENT_CUM_RETURN_SUCCESS = 'GET_CLIENT_CUM_RETURN_SUCCESS'
export const GET_CLIENT_CUM_RETURN_FAIL = 'GET_CLIENT_CUM_RETURN_FAIL'

export const GET_CLIENT_HOLDING_START = 'GET_CLIENT_HOLDING_START'
export const GET_CLIENT_HOLDING_SUCCESS = 'GET_CLIENT_HOLDING_SUCCESS'
export const GET_CLIENT_HOLDING_FAIL = 'GET_CLIENT_HOLDING_FAIL'

export const GET_ALLOCATION_COMPOSITION_START = 'GET_ALLOCATION_COMPOSITION_START'
export const GET_ALLOCATION_COMPOSITION_SUCCESS = 'GET_ALLOCATION_COMPOSITION_SUCCESS'
export const GET_ALLOCATION_COMPOSITION_FAIL = 'GET_ALLOCATION_COMPOSITION_FAIL'

export const GET_ACCOUNTS_START = 'GET_ACCOUNTS_START'
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const GET_ACCOUNTS_FAIL = 'GET_ACCOUNTS_FAIL'
export const ACCOUNTS_CLEAR = 'ACCOUNTS_CLEAR'

export const GET_ACCOUNT_TYPES_START = 'GET_ACCOUNT_TYPES_START'
export const GET_ACCOUNT_TYPES_SUCCESS = 'GET_ACCOUNT_TYPES_SUCCESS'
export const GET_ACCOUNT_TYPES_FAIL = 'GET_ACCOUNT_TYPES_FAIL'

export const GET_ACCOUNT_PORTIFOLIO_START = 'GET_ACCOUNT_PORTIFOLIO_START'
export const GET_ACCOUNT_PORTIFOLIO_SUCCESS = 'GET_ACCOUNT_PORTIFOLIO_SUCCESS'
export const GET_ACCOUNT_PORTIFOLIO_FAIL = 'GET_ACCOUNT_PORTIFOLIO_FAIL'
export const ACCOUNT_PORTFOLIO_HOLDING_CLEAR = 'ACCOUNT_PORTFOLIO_HOLDING_CLEAR'

export const GET_ALLOCATION_START = 'GET_ALLOCATION_START'
export const GET_ALLOCATION_SUCCESS = 'GET_ALLOCATION_SUCCESS'
export const GET_ALLOCATION_FAIL = 'GET_ALLOCATION_FAIL'

export const CREATE_ACCOUNT_START = 'CREATE_ACCOUNT_START'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAIL = 'CREATE_ACCOUNT_FAIL'

export const SUBSCRIBE_ACCOUNT_START = 'SUBSCRIBE_ACCOUNT_START'
export const SUBSCRIBE_ACCOUNT_SUCCESS = 'SUBSCRIBE_ACCOUNT_SUCCESS'
export const SUBSCRIBE_ACCOUNT_FAIL = 'SUBSCRIBE_ACCOUNT_FAIL'
export const SUBSCRIBE_ACCOUNT_CLEAR = 'SUBSCRIBE_ACCOUNT_CLEAR'

export const GET_CLIENT_ASSET_SIZE_START = 'GET_CLIENT_ASSET_SIZE_START'
export const GET_CLIENT_ASSET_SIZE_SUCCESS = 'GET_CLIENT_ASSET_SIZE_SUCCESS'
export const GET_CLIENT_ASSET_SIZE_FAIL = 'GET_CLIENT_ASSET_SIZE_FAIL'

export const GET_ACCOUNT_ASSET_SIZE_START = 'GET_ACCOUNT_ASSET_SIZE_START'
export const GET_ACCOUNT_ASSET_SIZE_SUCCESS = 'GET_ACCOUNT_ASSET_SIZE_SUCCESS'
export const GET_ACCOUNT_ASSET_SIZE_FAIL = 'GET_ACCOUNT_ASSET_SIZE_FAIL'

export const CREATE_PORTIFOLIO_ASSET_SIZE_START = 'CREATE_PORTIFOLIO_ASSET_SIZE_START'
export const CREATE_PORTIFOLIO_ASSET_SIZE_SUCCESS = 'CREATE_PORTIFOLIO_ASSET_SIZE_SUCCESS'
export const CREATE_PORTIFOLIO_ASSET_SIZE_FAIL = 'CREATE_PORTIFOLIO_ASSET_SIZE_FAIL'

export const GET_ACCOUNT_FUNDING_START = 'GET_ACCOUNT_FUNDING_START'
export const GET_ACCOUNT_FUNDING_SUCCESS = 'GET_ACCOUNT_FUNDING_SUCCESS'
export const GET_ACCOUNT_FUNDING_FAIL = 'GET_ACCOUNT_FUNDING_FAIL'

export const GET_ACCOUNT_PORTIFOLIO_HOLDING_START = 'GET_ACCOUNT_PORTIFOLIO_HOLDING_START'
export const GET_ACCOUNT_PORTIFOLIO_HOLDING_SUCCESS = 'GET_ACCOUNT_PORTIFOLIO_HOLDING_SUCCESS'
export const GET_ACCOUNT_PORTIFOLIO_HOLDING_FAIL = 'GET_ACCOUNT_PORTIFOLIO_HOLDING_FAIL'

export const SET_DATE = 'SET_DATE'

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'

export const SET_ACCOUNT = 'SET_ACCOUNT'

export const REMOVE_USER = 'REMOVE_USER'

export const SET_CLIENT_ASSET_SIZE = 'SET_CLIENT_ASSET_SIZE'

export const CREATE_SECURITY = 'CREATE_SECURITY'
export const CREATE_ACCOUNT_TYPE = 'CREATE_ACCOUNT_TYPE'
export const CREATE_ALLOCATION = 'CREATE_ALLOCATION'
export const CREATE_GOAL = 'CREATE_GOAL'
export const CREATE_MODEL = 'CREATE_MODEL'
export const CREATE_MODEL_HOLDING = 'CREATE_MODEL_HOLDING'
export const CREATE_CLIENT_ADMIN_SERVICE = 'CREATE_CLIENT_ADMIN_SERVICE'
export const CREATE_CLIENT_NUCLEUS_SERVICE = 'CREATE_CLIENT_NUCLEUS_SERVICE'
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT'
export const SUBSCRIBE_ACCOUNT = 'SUBSCRIBE_ACCOUNT'
export const CREATE_PORTFOLIO = 'CREATE_PORTFOLIO'
export const CREATE_PORTFOLIO_HOLDING = 'CREATE_PORTFOLIO_HOLDING'
export const CREATE_DECISION_TREE = 'CREATE_DECISION_TREE'
export const CREATE_QUESTIONNAIRE = 'CREATE_QUESTIONNAIRE'
export const CREATE_NODE = 'CREATE_NODE'
export const CREATE_NODE_RELATIONSHIP = 'CREATE_NODE_RELATIONSHIP'
export const CREATE_TRANSACTION_CODES = 'CREATE_TRANSACTION_CODES'
export const CREATE_PORTFOLIO_TRANSACTIONS = 'CREATE_PORTFOLIO_TRANSACTIONS'
export const CREATE_PORTFOLIO_ASSET_SIZE = 'CREATE_PORTFOLIO_ASSET_SIZE'


export const GET_ALL_SECURITIES_START = 'GET_ALL_SECURITIES_START'
export const GET_ALL_SECURITIES_SUCCESS = 'GET_ALL_SECURITIES_SUCCESS'
export const GET_ALL_SECURITIES_FAIL = 'GET_ALL_SECURITIES_FAIL'

export const GET_TRANSACTIONS_START = 'GET_TRANSACTIONS_START'
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL'

export const RETRIEVE_MODEL_START = 'RETRIEVE_MODEL_START'
export const RETRIEVE_MODEL_SUCCESS = 'RETRIEVE_MODEL_SUCCESS'
export const RETRIEVE_MODEL_FAIL = 'RETRIEVE_MODEL_FAIL'


export const GET_MODEL_HOLDINGS_START = 'GET_MODEL_HOLDINGS_START'
export const GET_MODEL_HOLDINGS_SUCCESS = 'GET_MODEL_HOLDINGS_SUCCESS'
export const GET_MODEL_HOLDINGS_FAIL = 'GET_MODEL_HOLDINGS_FAIL'

export const CREATE_PORFOLIO_HOLDING_START = 'CREATE_PORFOLIO_HOLDING_START'
export const CREATE_PORFOLIO_HOLDING_SUCCESS = 'CREATE_PORFOLIO_HOLDING_SUCCESS'
export const CREATE_PORFOLIO_HOLDING_FAIL = 'CREATE_PORFOLIO_HOLDING_FAIL'